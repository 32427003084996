"use strict";

document.addEventListener( 'DOMContentLoaded', function () {

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * hamburger, aside functionality
    // *
    // *

    const hamburger = document.querySelector(".js-menu-hamburger");
    const aside = document.querySelector(".js-menu");
    const menuclose = document.querySelector(".js-menu-close");

    function toggleMenuState() {
        hamburger.classList.toggle("open");
        aside.classList.toggle("open");

        if (document.body.style.overflow === "hidden") {
            document.body.style.overflow = "auto";
        } else {
            document.body.style.overflow = "hidden";
        }
    }

    if (hamburger !== null) {
        hamburger.onclick = function(e) {
            toggleMenuState();
        }
    }

    if (menuclose !== null) {
        menuclose.onclick = function(e) {
            toggleMenuState();
        }
    }

    document.addEventListener('keydown', function(event) {
        if (event.key === "Escape") {
            event.preventDefault();
            toggleMenuState();
        }
    });

    document.onclick = function(e){
        if (e.target.classList.contains('js-menu-layer')) {
            toggleMenuState();
        }
    };


    var hasSubmenu = document.querySelectorAll('.has-submenu');

function closeAllSubmenusExcept(exceptSubmenu) {
    for (var i = 0; i < hasSubmenu.length; i++) {
        var submenu = hasSubmenu[i].querySelector('.submenu');
        if (submenu !== exceptSubmenu) {
            hasSubmenu[i].classList.remove('active');
        }
    }
}

for (var i = 0; i < hasSubmenu.length; i++) {
    var arrow = hasSubmenu[i].querySelector('.arrow');
    arrow.addEventListener('click', function (event) {
        event.stopPropagation();
        var menuItem = this.parentElement;
        var submenu = menuItem.querySelector('.submenu');
        if (menuItem.classList.contains('active')) {
            menuItem.classList.remove('active');
        } else {
            closeAllSubmenusExcept(submenu);
            menuItem.classList.add('active');
        }
    });
}





    
    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * splide heroSlider
    // *
    // *

    const heroSlider = document.getElementsByClassName('js-hero-slider');
    
    if (heroSlider !== null) {
        for (let i = 0; i < heroSlider.length; i++) {
            new Splide(heroSlider[i], {
                type            : 'fade',
                pauseOnHover    : true,
                rewind          : true,
                lazyLoad        : 'nearby'
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * splide imageSlider
    // *
    // *

    const imageSlider = document.getElementsByClassName('js-image-slider');
    
    if (imageSlider !== null) {
        for (let i = 0; i < imageSlider.length; i++) {
            new Splide(imageSlider[i], {
                type            : 'fade',
                pauseOnHover    : true,
                rewind          : true,
                lazyLoad        : 'nearby'
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * splide textSlider
    // *
    // *

    const textSlider = document.getElementsByClassName('js-text-slider');
    
    if (textSlider !== null) {
        for (let i = 0; i < textSlider.length; i++) {
            new Splide(textSlider[i], {
                type            : 'slide',
                rewind          : true,
                arrows          : true,
                pagination      : true,
                lazyLoad        : 'nearby',
                perPage         : 1,
                gap             : '3rem',
                breakpoints: {
                    768: {
                        perPage: 1,
                    }
                }
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * splide imageTextSlider
    // *
    // *

    // Get all image-text sliders on the page
    const imageTextSliders = document.querySelectorAll('.js-imagetext-slider');

    // Loop through each image-text slider
    imageTextSliders.forEach(function(imageTextSlider) {
        // Create a new Splide instance for the slider
        const slider = new Splide(imageTextSlider, {
            type: 'slide',
            rewind: true,
            arrows: true,
            pagination: true,
            lazyLoad: 'nearby',
            gap: '3rem'
        });

        // Call the updateSlidePosition function when the slider is mounted
        slider.on('mounted', function() {
            updateSlidePosition(slider);
        });

        // Call the updateSlidePosition function when the slider is moved
        slider.on('moved', function() {
            updateSlidePosition(slider);
        });

        // Mount the slider.
        slider.mount();
    });

    // A function that updates the slide position element with the current slide index and total slides
    function updateSlidePosition(slider) {
        const currentSlideIndex = slider.index + 1;
        const totalSlides = slider.length;

        // Pad the slide index and total slides with leading zeros, if needed
        const currentSlideIndexPadded = currentSlideIndex.toString().padStart(2, '0');
        const totalSlidesPadded = totalSlides.toString().padStart(2, '0');

        // Construct the slide position string
        const slidePosition = `${currentSlideIndexPadded}/${totalSlidesPadded}`;

        // Get the slide position element for this slider instance
        const slidePositionEl = slider.root.querySelector('.js-slide-position');

        // Update the slide position element with the slide position string
        if (slidePositionEl) {
            slidePositionEl.textContent = slidePosition;
        }
    }





    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * splide instagram
    // *
    // *
    
    const instagramSlider = document.querySelectorAll(".js-instagram-slider");

    if (instagramSlider !== null) {
        for (let i = 0; i < instagramSlider.length; i++) {
            const splide = new Splide(instagramSlider[i], {
                type            : 'loop',
                rewind          : true,
                autoplay        : true,
                arrows          : true,
                pagination      : false,
                lazyLoad        : 'nearby',
                perPage         : 3,
                perMove         : 1,
                speed           : 1000,
                interval        : 2000,
                gap             : '6rem',
                padding: { 
                    left: 0, 
                    right: 0 
                },
                breakpoints: {
                    768: {
                        perMove : 1,
                        perPage: 2,
                    },
                    576: {
                        perMove : 1,
                        perPage : 1,
                        padding : '0'
                    },
                }
            }).mount();
        }
    };


    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scrollTo
    // *
    // *

    // Get all elements with the class "js-scrollTo".
    const anchors = document.querySelectorAll(".js-scrollTo");

    // Loop through each element and add a click event listener.
    for (const anchor of anchors) {
        anchor.addEventListener("click", clickHandler);
    }

    // A function that handles click events on elements with the class "js-scrollTo".
    function clickHandler(e) {
        e.preventDefault();

        // Get the ID of the target element from the href attribute of the clicked element.
        let href = this.getAttribute("href").split("#");
        let newAnchor = "#my" + href[1];

        // Get the offset top of the target element.
        let offsetTop = document.querySelector(newAnchor).offsetTop;

        // Scroll the page to the target element.
        scroll({
            top: offsetTop,
            behavior: "smooth"
        });
    }




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * hash link jump to content
    // *
    // *

    window.addEventListener('load', function () {
        let getHash = window.location.hash;
        let elementExists = document.getElementById("my" + getHash.slice(1));

        if (getHash !== null && elementExists !== null) {
            let name = "#my" + getHash.slice(1);
            let offsetTop = document.querySelector(name).offsetTop;

            scroll({
                top: offsetTop,
                behavior: "smooth"
            });
        }

    }, false);


    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scrollToTop
    // *
    // *
    
    const scrolltotop  = document.querySelector(".js-scrolltop");

    if (scrolltotop !== null) {
        // show and hide scrollToTop btn on scroll
        document.addEventListener('scroll', function(e) {
            if (window.scrollY > 300) {
                scrolltotop.classList.add("show");
            } else {
                scrolltotop.classList.remove("show");
            }
        });

        // onclick scroll to top
        scrolltotop.onclick = function(e){
            e.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }

        // onkeydown scroll to top or bottom
        scrolltotop.addEventListener("keydown", function (event) {
            if (event.keyCode === 13 || event.keyCode === 32) {
                event.preventDefault();
    
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }   
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    // * anchor nav scrollTo
    // *
    // *
    
    // get browser width
    let browserWidth = window.innerWidth;
    let anchorBox = document.querySelector('.js-anchor-nav');
    let anchorBoxSticky = document.querySelector('.js-anchor-sticky');

    // check if anchor modul is set to sticky
    if (anchorBoxSticky !== null) {
        window.addEventListener('scroll', function(e) {
            // get pos from anchorbox
            let pos = Math.round(anchorBox.getBoundingClientRect().y);

            if (pos <= 0) {
                anchorBox.classList.add("stick");
            } else {
                anchorBox.classList.remove("stick");
            }
        });
    }

    // check if anchor modul is set
    if (anchorBox !== null) {
        // needed for sticky anchor to calculate offsetTop
        anchorBoxHeight = anchorBox.offsetHeight;

        if (browserWidth > 1280) {
            anchorBoxHeight = anchorBoxHeight;
        } else {
            anchorBoxHeight = 0;
        }

        const anchors = document.querySelectorAll(".js-scrollTo"); 
        
        for (const anchor of anchors) {
            anchor.addEventListener("click", clickHandler);
        }

        function clickHandler(e) {
            e.preventDefault();
        
            let href = this.getAttribute("href").split("#");
            let newAnchor = "#my" + href[1]
            let offsetTop = document.querySelector(newAnchor).offsetTop - anchorBoxHeight;

            scroll({
                top: offsetTop,
                behavior: "smooth"
            });
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * modal window
    // *
    // *

    let modalWindow = document.querySelector(".js-modal");
    let modalOpen = document.querySelector(".js-modal-btn-open");
    let modalClose = document.querySelector(".js-modal-btn-close");
    
    /* onclick show modal window */
    if (modalOpen !== null) {
        modalOpen.onclick = function() {
            modalWindow.style.display = "block";
            modalClose.focus();
        }
    }
    
    /* close modal window */
    if (modalClose !== null) {
        modalClose.onclick = function() {
            modalWindow.style.display = "none";
        }
    }
    
    /* clicks anywhere outside close modal window */
    window.onclick = function(event) {
        if (event.target == modalWindow) {
            modalWindow.style.display = "none";
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion open every tab
    // *
    // *

    const title = document.querySelectorAll(".js-accordion-title");

    if (title !== null) {
        for (var i=0; i < title.length; i++) {
            title[i].onclick = function(e) {
                this.classList.toggle("open");
                this.nextElementSibling.classList.toggle("open");

                let expanded = this.getAttribute('aria-expanded');
        
                if (expanded == 'false') {
                    this.setAttribute('aria-expanded', 'true')
                } else {
                    this.setAttribute('aria-expanded', 'false')
                }
            }
            title[i].addEventListener("keydown", function (event) {
                if (event.keyCode === 13 || event.keyCode === 32) {
        
                    event.preventDefault();
        
                    this.classList.toggle("open");
                    this.nextElementSibling.classList.toggle("open");
        
                    let expanded = this.getAttribute('aria-expanded');
        
                    if (expanded == 'false') {
                        this.setAttribute('aria-expanded', 'true')
                    } else {
                        this.setAttribute('aria-expanded', 'false')
                    }
                }
            });
        }
    }


    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * open external links in new tab
    // *
    // *

    function externalLinks() {
        for(let links = document.getElementsByTagName("a"), number = 0; number < links.length; number++) {
            let currentLink = links[number];
            
            // check if link has same hostname. if not add target blank.
            currentLink.getAttribute("href") && currentLink.hostname !== location.hostname && (currentLink.target = "_blank")
        }
    };
    
    externalLinks();




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * simpleParallax
    // *
    // *

    let parallaxImages = document.getElementsByClassName('js-parallax');
    new simpleParallax(parallaxImages, {
        delay: 1,
        scale: 1.05
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * aos
    // *
    // *

    AOS.init({
        offset: 200,
        duration: 700,
        delay: 100,
        disable: 'mobile',
        easing: 'ease-out-back'
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * gsap
    // *
    // *

    // gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
    // ScrollTrigger.normalizeScroll(true);

    // let smoother = ScrollSmoother.create({
    //     smooth: 1.5,
    //     effects: true,
    // });


});
